import { ActionIcon, Box, Menu } from "@mantine/core";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";
import { TbCopy, TbPrinter, TbTrash } from "react-icons/tb";
import { TfiMoreAlt } from "react-icons/tfi";
import { NumericFormat } from "react-number-format";
import { NavLink } from "react-router-dom";
import { dbProvider } from "../../db";

export default function ListOfAllChecks({ checks }) {
  const sortedChecks = checks
    .toSorted((a, b) => new Date(a.date) - new Date(b.date))
    .reverse();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });

  const [records, setRecords] = useState(sortedChecks);

  useEffect(() => {
    const data = checks.toSorted((a, b) => new Date(a.date) - new Date(b.date));
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
  }, [sortStatus, checks]);

  return (
    <DataTable
      withTableBorder
      borderRadius="sm"
      withColumnBorders
      striped
      highlightOnHover
      records={records}
      columns={[
        { accessor: "date", sortable: true },
        {
          accessor: "amount",
          textAlign: "right",
          render: (check) => (
            <NumericFormat
              value={check.amount}
              prefix={"$"}
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
            />
          ),
        },
        { accessor: "payee" },
        { accessor: "memo" },
        {
          accessor: "actions",
          title: <Box mr={6}>Actions</Box>,
          textAlign: "center",
          render: (check) => (
            <Menu shadow="md" withArrow offset={-5}>
              <Menu.Target>
                <ActionIcon
                  variant="transparent"
                  color="black"
                  aria-label="Settings"
                >
                  <TfiMoreAlt />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<TbPrinter />}
                  component={NavLink}
                  to={`/checks/${check._id}/print`}
                >
                  Print Check
                </Menu.Item>
                <Menu.Item
                  leftSection={<TbCopy />}
                  component={NavLink}
                  to={`/checks/new?from=${check._id}`}
                >
                  Duplicate
                </Menu.Item>

                <Menu.Item
                  leftSection={<TbTrash />}
                  color="red"
                  onClick={async () => {
                    if (window.confirm("Are you sure?")) {
                      const db = await dbProvider.db();
                      await db.del(check._id);
                    }
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ),
        },
      ]}
      idAccessor="_id"
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      minHeight={checks.length == 0 ? 150 : 50}
      noRecordsText="No checks found"
    />
  );
}
