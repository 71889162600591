import { Container } from "@mantine/core";
import { useActionData, useNavigate } from "react-router-dom";
import { dbProvider } from "../../db.ts";
import AccountForm from "./_AccountForm.tsx";

export default function New() {
  const navigate = useNavigate();
  async function CreateAccount(values) {
    const db = await dbProvider.db();
    await db.put({ type: "account", ...values });
    navigate("/accounts");
  }

  const initialValues = {
    name: "",
    address: "",
    routingNumber: "",
    accountNumber: "",
    checkNumber: 500,
    displayName: "",
  };

  return (
    <>
      <Container size="xs">
        <AccountForm
          initialValues={initialValues}
          action={CreateAccount}
        ></AccountForm>
      </Container>
    </>
  );
}
