import { Hanko, User } from "@teamhanko/hanko-elements";

interface AuthProvider {
  isAuthenticated(): Promise<boolean | undefined>;
  user(): Promise<null | AuthenticatedUser>;
  signout(): Promise<void>;
  setUser(user: AuthenticatedUser): void;
  hanko(): Hanko;
}

const hankoApiURL = import.meta.env.VITE_HANKO_API_URL;
let hanko: Hanko;
let user: AuthenticatedUser | null;

export interface AuthenticatedUser extends User {
  subscription?: any; // Replace 'any' with the appropriate type for subscription
}

function getHanko() {
  if (!hanko) {
    hanko = new Hanko(hankoApiURL);
  }
  return hanko;
}

async function getUser() {
  if (!user) {
    const hanko = getHanko();
    try {
      if (await authProvider.isAuthenticated()) {
        user = await hanko?.user.getCurrent();
        const subscription = await getSubscription();
        user.subscription = subscription
       }
    } catch {
      user = null;
    }
  }
  return user;
}

async function getSubscription() {
  const subscriptionApiUrl = import.meta.env.VITE_API_URL;
  try {
    const response = await fetch(`${subscriptionApiUrl}/subscription`,  { credentials: "include" });
    const {subscription, error} = await response.json();
    if (error) {
      throw new Error(error);
    }
    return subscription;
  } catch (error) {
    console.error('Error fetching subscription status:', error);
    return null;
  }
}

export const authProvider: AuthProvider = {
  async isAuthenticated() {
    const hanko = getHanko();
    return hanko?.session.isValid();
  },
  async user() {
    console.time("fetching user");
    user = await getUser();
    console.timeEnd("fetching user");
    return user;
  },
  async signout() {
    user = null;
    const hanko = getHanko();
    await hanko.user.logout();
  },
  setUser(newUser) {
    user = newUser;
  },
  hanko() {
    if (!hanko) {
      hanko = new Hanko(hankoApiURL);
    }
    return hanko;
  },
};
