import "@mantine/core/styles.layer.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-datatable/styles.layer.css";
import Logo from "./Logo.tsx";
import "./print.css";

import classes from "./App.module.css";

import {
  Anchor,
  AppShell,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  MantineProvider,
  Menu,
  Modal,
  Paper,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {
  Outlet,
  NavLink as RouterLink,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { bgColor, theme } from "../theme.ts";

import { useDisclosure } from "@mantine/hooks";
import { authProvider, type AuthenticatedUser } from "../auth.ts";
import HankoAuth from "./_Auth.tsx";

export async function loader() {
  const user = await authProvider.user();
  return { user };
}

export default function App() {
  const navigate = useNavigate();

  async function logout() {
    await authProvider.signout();
    navigate(0);
  }

  const { user } = useRouteLoaderData("root") as {
    user: AuthenticatedUser | null;
  };

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-center" />
      <AppShell
        header={{ height: 40 }}
        withBorder={false}
        padding="lg"
        bg={bgColor}
      >
        <AppShell.Header bg={bgColor} className={classes.header}>
          <Flex
            mr="auto"
            align="center"
            p={4}
            style={{ gridColumn: 1, gridRow: 1 }}
          >
            <Anchor to="/" component={RouterLink} style={{ zIndex: 10 }}>
              <Logo height={31} />
            </Anchor>
          </Flex>
          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            style={{ gridColumn: "1 / -1", gridRow: 1 }}
          >
            <Anchor to="/" component={RouterLink} fw="bold">
              <Center inline>
                <Box ml={5}>Checks</Box>
              </Center>
            </Anchor>

            <Anchor to="/accounts" component={RouterLink} fw="bold">
              <Center inline>
                <Box ml={5}>Payment Accounts</Box>
              </Center>
            </Anchor>

            <Anchor
              to="mailto:hi@usequickcheck.com"
              component={RouterLink}
              fw="bold"
            >
              <Center visibleFrom="sm" inline>
                <Box ml={5}>Help</Box>
              </Center>
            </Anchor>
          </Flex>

          <Box ml="auto" style={{ gridColumn: 3, gridRow: 1 }}>
            {user ? (
              <Menu
                withArrow
                position="top-end"
                styles={{ dropdown: { transform: "translateX(-10px)" } }}
              >
                <Menu.Target>
                  <Avatar
                    src={`https://api.dicebear.com/8.x/initials/svg?seed=${user.email}&radius=50&size=32&backgroundColor=7cb342`}
                    style={{ cursor: "pointer", padding: "0.2rem" }}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={logout}>Log out</Menu.Item>
                  <Menu.Item
                    component="a"
                    href={`${
                      import.meta.env.VITE_API_URL
                    }/stripe/manage-billing`}
                  >
                    Manage Billing
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ) : (
              <Button
                m={4}
                variant="subtle"
                onClick={open}
                className={classes.headShaking}
              >
                <Box ml={8} className={classes.pulsatingCircle} />
                <Box ml={10}>Log in</Box>
                <Modal
                  opened={opened}
                  onClose={close}
                  title="Sign in to save your accounts and checks."
                  centered
                >
                  <HankoAuth close={close} />
                </Modal>
              </Button>
            )}
          </Box>
        </AppShell.Header>

        <AppShell.Main mx="auto" maw="68rem">
          <Paper shadow="sm" pt="xl" pb="xl">
            <Outlet />
            <Modal
              opened={!!(user && user.subscription?.status !== "active")}
              centered
              withCloseButton={false}
            >
              <Center>
                Support this site for just $3.99/month! Your contribution helps
                me keep things running smoothly and improve my offerings. I
                appreciate your support!
                <form
                  action={`${
                    import.meta.env.VITE_API_URL
                  }/stripe/create-checkout-session`}
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="priceId"
                    value={import.meta.env.VITE_STRIPE_PRICE_ID}
                  />
                  <Button type="submit">👉 Subscribe Now!</Button>
                </form>
              </Center>
            </Modal>
          </Paper>
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
}
