import { Button, Container } from "@mantine/core";
import { NavLink, useLoaderData } from "react-router-dom";
import { useFireproof } from "use-fireproof";
import { dbProvider } from "../db.ts";
import ListOfAllChecks from "./checks/_List.tsx";

export async function loader() {
  const db = await dbProvider.db();
  const result = await db.query("type", { key: "check" });
  return { db: db, rows: result.rows, docs: result.rows.map((row) => row.doc) };
}

export default function Home() {
  const initialData = useLoaderData();
  const checks = useFireproof(initialData.db).useLiveQuery(
    "type",
    { key: "check" },
    initialData.rows
  ).docs;

  return (
    <Container>
      <Button
        to="/checks/new"
        mb="sm"
        size="compact-lg"
        fs="sm"
        component={NavLink}
      >
        + New Check
      </Button>
      <ListOfAllChecks checks={checks} />
    </Container>
  );
}
