import { Container } from "@mantine/core";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { dbProvider } from "../../db.ts";
import AccountForm from "./_AccountForm.tsx";

export async function loader({ params }) {
  const db = await dbProvider.db();
  return await db.get(params.id);
}
export default function Edit() {
  const account = useLoaderData();
  const { id } = useParams();
  const navigate = useNavigate();

  async function updateAccount(values) {
    const db = await dbProvider.db();
    const doc = await db.get(id);
    await db.put({ ...doc, ...values });
    navigate("/accounts");
  }

  return (
    <Container size="xs">
      <AccountForm initialValues={account} action={updateAccount}></AccountForm>
    </Container>
  );
}
