import classes from "./New.module.css";

import {
  Anchor,
  Autocomplete,
  Button,
  Center,
  Container,
  Group,
  NumberInput,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useRef } from "react";
import { TbCurrencyDollar } from "react-icons/tb";
import { redirect, useLoaderData, useNavigate } from "react-router-dom";
import { dbProvider } from "../../db.ts";
import CheckPreview from "./_CheckPreview.tsx";

export async function loader({ request }) {
  const db = await dbProvider.db();
  const url = new URL(request.url);
  const from = url.searchParams.get("from");

  // If we are duplicating a check, just get the original check values
  if (from) {
    console.time("fetch check");
    const oldCheck = await db.get(from);
    console.timeEnd("fetch check");
    return { accounts: [{ ...oldCheck }] };
  }

  console.time("fetch accounts");
  const resp = await db.query("type", { key: "account" });
  console.timeEnd("fetch accounts");

  const accounts = resp.rows.map((row) => row.doc);

  if (accounts.length == 0) {
    notifications.show({
      title: "No payment accounts",
      message: "Please, add a bank account.",
    });
    return redirect("/accounts/new");
  }

  const checks = (await db.query("type", { key: "check" })).rows;
  // Pre populate the payees for dropdown, ordered based on most checks
  const payees = checks
    .map((row) => row.doc.payee)
    .reduce((a, v) => {
      a[v] = (a[v] ?? 0) + 1;
      return a;
    }, {});

  const sortedPayees = Object.entries(payees)
    .sort((a, b) => b[1] - a[1])
    .map((x) => x[0]);

  const lastCheckNumbers = await Promise.all(
    accounts.map(async (account) => {
      if (!account.lastCheckNumber) {
        const filteredChecks = checks
          .map((row) => row.doc)
          .filter(
            (check) =>
              check.checkNumber &&
              check.accountNumber === account.accountNumber &&
              check.routingNumber === account.routingNumber
          );

        const checkNumbers = filteredChecks.map((check) => check.checkNumber);
        const lastUsedCheckNumber =
          checkNumbers.length > 0
            ? Math.max(...checkNumbers)
            : account.checkNumber - 1;
        return parseInt(lastUsedCheckNumber);
      }
      return account.lastCheckNumber; // Return existing lastCheckNumber if it exists
    })
  );
  await Promise.all(
    accounts.map(async (account, index) => {
      if (!account.lastCheckNumber) {
        account.lastCheckNumber = lastCheckNumbers[index];
        await db.put(account); // Save the updated account to the database
      }
    })
  );

  return { accounts, sortedPayees };
}

export function New() {
  const navigate = useNavigate();
  const checkPreviewRef = useRef(null);

  async function CreateCheck(values) {
    const db = await dbProvider.db();
    const account = values.account;
    delete values.account;
    await db.put({
      ...account,
      printon: values.printon,
      lastCheckNumber: parseInt(values.checkNumber),
    });
    const ok = await db.put({ type: "check", ...values });
    navigate(`/checks/${ok.id}/print`);
  }

  const data = useLoaderData();
  const accounts = data.accounts;

  const form = useForm({
    initialValues: {
      payer: accounts[0]._id,
      payee: accounts[0].payee || "",
      amount: accounts[0].amount || "",
      memo: accounts[0].memo || "",
      date: new Date(),
      printon: accounts[0].printon || "paper",
      checkNumber: accounts[0].lastCheckNumber + 1,
    },

    transformValues: (values) => {
      const account = accounts.find((account) => account._id == values.payer);
      return {
        ...values,
        date: dayjs(values.date).format("MM/DD/YYYY"),
        address: account.address,
        name: account.name,
        accountNumber: account.accountNumber,
        routingNumber: account.routingNumber,
        account: account,
        checkType: "business",
      };
    },

    validate: {
      payee: isNotEmpty("Enter a name"),
      amount: isNotEmpty("Enter amount"),
      date: isNotEmpty("Enter date"),
    },
  });

  return (
    <>
      <Container size="xs">
        <form
          method="post"
          action="/checks"
          onSubmit={form.onSubmit((_v, e) => {
            CreateCheck(_v);
          })}
          id="new-check-form"
        >
          <Stack gap="xs">
            <Select
              label="Payment Account"
              name="payer"
              data={accounts.map((account) => ({
                value: account._id,
                label: `${
                  account.displayName ? account.displayName + " - " : ""
                } ${account.name}`,
              }))}
              {...form.getInputProps("payer")}
              allowDeselect={false}
              withAsterisk
              comboboxProps={{
                shadow: "md",
                transitionProps: { transition: "pop", duration: 200 },
              }}
              onChange={(val, _option) => {
                form.setValues((prev) => {
                  return {
                    ...prev,
                    ...{
                      payer: val,
                      checkNumber:
                        accounts.filter((account) => account._id == val)[0]
                          .lastCheckNumber + 1,
                    },
                  };
                });
              }}
            />

            <Autocomplete
              label="Pay To"
              name="payee"
              {...form.getInputProps("payee")}
              withAsterisk
              data={data.sortedPayees}
              limit={5}
              comboboxProps={{
                shadow: "md",
                transitionProps: { transition: "pop", duration: 200 },
              }}
            />

            <NumberInput
              withAsterisk
              label="Amount"
              name="amount"
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale
              leftSection={<TbCurrencyDollar />}
              {...form.getInputProps("amount")}
            />

            <DatePickerInput
              placeholder="Pick date"
              label="Date"
              withAsterisk
              name="date"
              {...form.getInputProps("date")}
            />

            <TextInput
              label="Check Number"
              name="checkNumber"
              {...form.getInputProps("checkNumber")}
            />

            <Textarea
              label="Memo"
              name="memo"
              {...form.getInputProps("memo")}
            />

            <Radio.Group
              name="printon"
              label="Print On"
              description={
                <div>
                  <Text component="div" size="sm">
                    Removes the border, if you have blank checks that you can
                    print on.
                  </Text>
                  <Text component="div" size="sm">
                    Works good with{" "}
                    <Anchor
                      target="_blank"
                      href="https://www.amazon.com/gp/product/B00L3NC8A8/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&th=1"
                    >
                      these cheap blank checks
                    </Anchor>
                  </Text>
                </div>
              }
              {...form.getInputProps("printon")}
            >
              <Group mt="xs">
                <Radio value="paper" label="Blank Sheet" />
                <Radio value="check" label="Blank Check" />
              </Group>
            </Radio.Group>

            <Group mt="10">
              <Button type="submit">Save</Button>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            </Group>
          </Stack>
        </form>
      </Container>

      <Center
        w="100%"
        pt="lg"
        mt="lg"
        className={classes["new-preview-container"]}
      >
        <Stack>
          <Center>
            <Title order={4}>Preview</Title>
          </Center>
          <CheckPreview
            ref={checkPreviewRef}
            data={form.getTransformedValues()}
          />

          {import.meta.env.DEV ? (
            <Button
              onClick={() => {
                if (!checkPreviewRef.current) return;
                const parentElement = document.getElementById("preview");
                const parentRect = parentElement.getBoundingClientRect();
                const draggableElements = Array.from(
                  checkPreviewRef.current.querySelectorAll(
                    '[data-draggable="true"]'
                  )
                );
                const positions = draggableElements.reduce((acc, el) => {
                  const elRect = el.getBoundingClientRect();
                  const x = elRect.left - parentRect.left;
                  const y = elRect.top - parentRect.top;
                  const id = el.getAttribute("data-id");

                  acc[id] = { position: { left: `${x}`, top: `${y}` } };

                  return acc;
                }, {});
                console.log(JSON.stringify({ positions }, null, 2));
              }}
            >
              Print Draggable Positions
            </Button>
          ) : (
            ""
          )}
        </Stack>
      </Center>
    </>
  );
}
