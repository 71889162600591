import {
  Button,
  Container,
  Group,
  MantineProvider,
  Text,
  Title,
} from "@mantine/core";
import classes from "./Error.module.css";

export function RootBoundary() {
  return (
    <MantineProvider>
      <Container className={classes.root}>
        {/* <div className={classes.label}>404</div> */}
        <Title className={classes.title}>You have found a secret place.</Title>
        <Text c="dimmed" size="lg" ta="center" className={classes.description}>
          Unfortunately, this is an error page. Don't worry, our development
          team was already notified.
        </Text>
        <Group justify="center">
          <Button variant="subtle" size="md" component="a" href="/">
            Take me back to home page
          </Button>
        </Group>
      </Container>
    </MantineProvider>
  );
}
