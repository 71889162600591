import { Button, Container, Group } from "@mantine/core";
import { NavLink, useLoaderData } from "react-router-dom";
import { dbProvider } from "../../db.ts";
import AccountCard from "./_AccountCard.tsx";

export async function loader() {
  let db = await dbProvider.db();
  return (await db.query("type", { key: "account" })).rows.map(
    (row) => row.doc
  );
}
export default function Index() {
  const accounts = useLoaderData();

  return (
    <Container>
      <Group gap={40} align="stretch">
        {accounts.map((account) => (
          <AccountCard account={account} key={account._id} />
        ))}

        <Button
          to="/accounts/new"
          mb="sm"
          size="compact-lg"
          fs="sm"
          component={NavLink}
        >
          + New Account
        </Button>
      </Group>
    </Container>
  );
}
