import posthog from 'posthog-js'
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Route,
  RouterProvider,
  useLocation,
  useNavigationType,
  useRouteError,
} from "react-router-dom";
import App, { loader as AppLoader } from "./layouts/App.tsx";
import Edit, { loader as AccountLoader } from "./pages/accounts/Edit.tsx";
import Index, {
  loader as AccountIndexLoader,
} from "./pages/accounts/Index.tsx";
import New from "./pages/accounts/New.tsx";
import {
  New as NewCheck,
  loader as NewCheckLoader,
} from "./pages/checks/New.tsx";
import { Print, loader as PrintLoader } from "./pages/checks/Print.tsx";
import Home, { loader as HomeLoader } from "./pages/Home.tsx";

import * as Sentry from "@sentry/react";
import { RootBoundary } from "./pages/Error.tsx";

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_QCSsFBika6mteMZOP9qB5mPSPBvwPgGcOgNAWn2eflp', { api_host: 'https://us.i.posthog.com' })
}

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://bbfa4559883436d30dd53909850d4a78@o4507115071471616.ingest.us.sentry.io/4507115072913408",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function SentryRouteErrorFallback() {
  const routeError = useRouteError();

  useEffect(() => {
    Sentry.captureException(routeError, { level: "fatal" });
  }, [routeError]);

  return <RootBoundary />;
}

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route
      id="root"
      path="/"
      element={<App />}
      loader={AppLoader}
      errorElement={<SentryRouteErrorFallback />}
    >
      <Route index element={<Home />} loader={HomeLoader} />
      <Route path="accounts">
        <Route index element={<Index />} loader={AccountIndexLoader} />
        <Route
          path=":id/edit"
          loader={AccountLoader}
          element={<Edit />}
        />
        <Route path="new" element={<New />} />
      </Route>
      <Route path="checks">
        <Route
          path="new"
          element={<NewCheck />}
          loader={NewCheckLoader}
        />
        <Route
          path=":id/print"
          element={<Print />}
          loader={PrintLoader}
        />
      </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
