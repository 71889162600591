import { useEffect, useRef, useState } from "react";

const Draggable = ({ children, dataid, top, left }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [xPosition, setXPosition] = useState(left); // Initial left percentage
  const [yPosition, setYPosition] = useState(top); // Initial top percentage
  const [initialMousePosition, setInitialMousePosition] = useState({});
  const dragContainerRef = useRef(null); // Reference to the draggable container

  const onMouseDown = (event) => {
    console.log(event);
    event.preventDefault();
    setInitialMousePosition({ x: event.clientX, y: event.clientY });
    setIsDragging(true);
  };

  useEffect(() => {
    const onMouseMove = (e) => {
      if (!dragContainerRef.current) return;

      const parentWidth = dragContainerRef.current.parentNode.offsetWidth;
      const parentHeight = dragContainerRef.current.parentNode.offsetHeight;
      const deltaX = ((e.clientX - initialMousePosition.x) / parentWidth) * 100;
      const deltaY =
        ((e.clientY - initialMousePosition.y) / parentHeight) * 100;

      setXPosition((prevX) => prevX + deltaX);
      setYPosition((prevY) => prevY + deltaY);
      setInitialMousePosition({ x: e.clientX, y: e.clientY });
    };

    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
    }
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, [isDragging, initialMousePosition]);

  useEffect(() => {
    const onMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", onMouseUp);
    return () => window.removeEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <div
      ref={dragContainerRef}
      data-id={dataid}
      data-draggable="true"
      style={{
        left: `${xPosition}%`,
        top: `${yPosition}%`,
        position: "absolute", // Ensure the element is positioned absolutely
      }}
      onMouseDown={import.meta.env.DEV ? onMouseDown : undefined}
    >
      {children}
    </div>
  );
};

export default Draggable;
