import { Button, Center, Container, Stack, Title } from "@mantine/core";
import { useLoaderData } from "react-router-dom";
import { dbProvider } from "../../db";
import CheckPreview from "./_CheckPreview";

export async function loader({ params }) {
  const db = await dbProvider.db();
  return await db.get(params.id);
}

export function Print() {
  const check = useLoaderData();

  return (
    <Container size="xs" style={{ containerType: "inline-size" }}>
      <Stack>
        <Center>
          <Title order={4}>Preview</Title>
        </Center>
        <CheckPreview data={check}> </CheckPreview>
        <Button onClick={window.print}>Print</Button>
      </Stack>
    </Container>
  );
}
