import { Box, Text as MantineText } from "@mantine/core";
import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import Draggable from "./Draggable";
import classes from "./_CheckPreview.module.css";

function num2words(number) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const scales = ["", "Thousand", "Million", "Billion", "Trillion"];

  // Separate the number into integer and decimal parts
  let parts = number.toString().split(".");
  let integerPart = parts[0].replace(/,/g, "");
  let decimalPart = parts.length > 1 ? parts[1] : "00"; // Default to "00" if no decimal part provided

  integerPart = parseInt(integerPart, 10);
  if (isNaN(integerPart)) return "Invalid input";

  if (integerPart === 0) return "Zero and 00/100";

  let word = "";

  for (let i = 0; integerPart > 0; i++) {
    let tempNumber = integerPart % 1000;
    let tempWord = "";

    if (tempNumber > 0) {
      // Process hundreds
      if (Math.floor(tempNumber / 100) > 0) {
        tempWord += ones[Math.floor(tempNumber / 100)] + " Hundred ";
        tempNumber %= 100;
      }

      // Process tens and ones
      if (tempNumber < 20 && tempNumber >= 10) {
        tempWord += teens[tempNumber - 10];
      } else if (tempNumber > 0) {
        // Check added to ensure tempNumber is processed only if > 0
        tempWord += tens[Math.floor(tempNumber / 10)];
        tempNumber %= 10;
        tempWord += tempNumber > 0 ? " " + ones[tempNumber] : "";
      }

      // Append scale if necessary
      tempWord = tempWord.trim() + (scales[i] ? " " + scales[i] : "");
    }

    word = tempWord + (word ? " " + word : "");

    integerPart = Math.floor(integerPart / 1000);
  }

  // Append "and XX/100" for the decimal part
  word += ` and ${parseInt(decimalPart.padEnd(2, "0"), 10)}/100`;

  return word.trim();
}

function CheckTypography(props) {
  return (
    <Draggable
      dataid={props.dataid}
      top={props.position.top}
      left={props.position.left}
    >
      <MantineText ff={props.style?.fontFamily || "Courier"} fs="sm" {...props}>
        {props.children}
      </MantineText>
    </Draggable>
  );
}
const initialPositionsMap = {
  personal: {
    name: {
      position: {
        left: 20,
        top: 10,
      },
    },
    address: {
      position: {
        left: 20,
        top: 31,
      },
      style: { whiteSpace: "pre", lineHeight: 1 },
    },
    payee: {
      position: {
        left: 67,
        top: 90,
      },
    },
    "amount-numeric": {
      position: {
        left: 445,
        top: 90,
      },
      style: { fontWeight: "bold" },
    },
    "amount-box": {
      position: {
        left: 441,
        top: 93,
      },
      // style: { border: "1px solid", width: 120, height: 20 },
    },
    "amount-words": {
      position: {
        left: 20,
        top: 138,
      },
      style: { lineHeight: 1 },
    },
    "date-text": {
      position: {
        left: 441,
        top: 23,
      },
    },
    "memo-text": {
      position: {
        left: 44,
        top: 188,
      },
    },
    "account-info": {
      position: {
        left: 179,
        top: 212,
      },
      style: { fontFamily: "MICR Encoding", fontSize: 24 },
    },
    "pay-to-the-order-of": {
      position: {
        left: 20,
        top: 100,
      },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 400,
        lineHeight: 2,
        fontWeight: "bold",
        // background:
        //   "linear-gradient(#000 0 0) bottom right / 89% 1px no-repeat",
      },
    },
    date: {
      position: {
        left: 410,
        top: 38,
      },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 155,
        lineHeight: 2,
        // background:
        //   "linear-gradient(rgb(0, 0, 0) 0px, rgb(0, 0, 0) 0px) right bottom / 89% 1px no-repeat",
      },
    },
    dollars: {
      position: {
        left: 20,
        top: 143,
      },
      style: {
        fontSize: 12,
        textTransform: "uppercase",
        width: 520,
        lineHeight: 1,
        // background:
        //   "linear-gradient(rgb(0, 0, 0) 0px, rgb(0, 0, 0) 0px) left bottom / 89% 1px no-repeat",
        display: "flex",
        justifyContent: "end",
      },
    },
    "memo-line": {
      position: {
        left: 20,
        top: 200,
      },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 330,
        lineHeight: 2,
        // background:
        //   "linear-gradient(rgb(0, 0, 0) 0px, rgb(0, 0, 0) 0px) right bottom / 94% 1px no-repeat",
      },
    },
    "authorized-signature": {
      position: {
        left: 387,
        top: 205,
      },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 200,
        lineHeight: 2,
        borderBottom: "1px solid",
      },
    },
    "check-number": {
      position: { left: 611, top: 10 },
      style: { fontWeight: "bold" },
    },
  },
  business: {
    name: {
      position: { left: 2.45, top: 2.98 },
      style: {},
    },
    address: {
      position: { left: 2.45, top: 9.23 },
      style: { whiteSpace: "pre", lineHeight: 1.2 },
    },
    payee: {
      position: { left: 7.97, top: 31.55 },
      style: {},
    },
    "amount-numeric": {
      position: { left: 69.12, top: 26.49 },
      style: { fontWeight: "bold" },
    },
    "amount-box": {
      position: { left: 69.61, top: 27.38 },
      style: { width: 120, height: 20 },
    },
    "amount-words": {
      position: { left: 2.21, top: 47.62 },
      style: {},
    },
    "date-text": {
      position: { left: 69.49, top: 11.01 },
      style: {},
    },
    "memo-text": {
      position: { left: 2.21, top: 68.45 },
      style: {},
    },
    "account-info": {
      position: { left: 29.78, top: 86.31 },
      style: { fontFamily: "MICR Encoding", fontSize: 24 },
    },
    "pay-to-the-order-of": {
      position: { left: 2.33, top: 34.52 },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        lineHeight: 2,
        fontWeight: "bold",
      },
    },
    date: {
      position: { left: 66.79, top: 14.29 },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 155,
        lineHeight: 2,
        fontWeight: "bold",
        paddingBottom: 5,
      },
    },
    dollars: {
      position: { left: 2.33, top: 52.08 },
      style: {
        fontSize: 12,
        textTransform: "uppercase",
        width: 740,
        lineHeight: 1,
        display: "flex",
        justifyContent: "end",
      },
    },
    "memo-line": {
      position: { left: 2.45, top: 67.56 },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 500,
        lineHeight: 2,
      },
    },
    "authorized-signature": {
      position: { left: 59.44, top: 72.02 },
      style: {
        fontSize: 6,
        textTransform: "uppercase",
        width: 300,
        lineHeight: 2,
        borderBottom: "1px solid",
      },
    },
    "check-number": {
      position: { left: 90.32, top: 2.98 },
      style: { fontWeight: "bold" },
    },
  },
};
const CheckPreview = forwardRef(({ data }, ref) => {
  const positions = initialPositionsMap["business"];

  return (
    <>
      <Box
        pos="relative"
        id="preview"
        ff="Courier"
        style={{
          width: data.checkType === "personal" ? "6.5in" : "8.5in",
          height: data.checkType === "personal" ? "2.5in" : "3.5in",
          outline: data.printon === "paper" ? `1px solid` : "",
        }}
        className={classes["preview"]}
        ref={ref}
        key={data.checkType}
      >
        <CheckTypography dataid="name" {...positions["name"]}>
          {data.name}
        </CheckTypography>

        <CheckTypography dataid="address" {...positions["address"]}>
          {data.address}
        </CheckTypography>

        <CheckTypography dataid="payee" {...positions["payee"]}>
          {data.payee}
        </CheckTypography>

        <CheckTypography
          dataid="amount-numeric"
          {...positions["amount-numeric"]}
        >
          <NumericFormat
            value={data.amount}
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
          />
        </CheckTypography>

        <CheckTypography dataid="amount-words" {...positions["amount-words"]}>
          {data.amount && num2words(data.amount)} DOLLARS
        </CheckTypography>

        <CheckTypography dataid="date-text" {...positions["date-text"]}>
          {data.date}
        </CheckTypography>

        <CheckTypography dataid="memo-text" {...positions["memo-text"]}>
          {data.memo}
        </CheckTypography>

        <CheckTypography dataid="account-info" {...positions["account-info"]}>
          ⑆{data.routingNumber}⑆ {data.accountNumber}⑈ {data.checkNumber}
        </CheckTypography>

        <CheckTypography dataid="check-number" {...positions["check-number"]}>
          {data.checkNumber}
        </CheckTypography>

        <Draggable
          dataid="pay-to-the-order-of"
          {...positions["pay-to-the-order-of"].position}
        >
          <Box style={positions["pay-to-the-order-of"].style}>
            <Box w={50} lh={1} style={{ letterSpacing: "0.05em" }}>
              Pay To The Order Of:
            </Box>
          </Box>
        </Draggable>

        <Draggable dataid="date" {...positions["date"].position}>
          <Box style={positions["date"].style}>
            <Box w={50} lh={1} style={{ letterSpacing: "0.05em" }}>
              Date:
            </Box>
          </Box>
        </Draggable>

        <Draggable dataid="amount-box" {...positions["amount-box"].position}>
          <Box style={positions["amount-box"].style}></Box>
        </Draggable>

        <Draggable dataid="memo-line" {...positions["memo-line"].position}>
          <Box style={positions["memo-line"].style}>
            <Box w={50} lh={1} style={{ letterSpacing: "0.05em" }}>
              Memo:
            </Box>
          </Box>
        </Draggable>

        <Draggable
          dataid="authorized-signature"
          {...positions["authorized-signature"].position}
        >
          <Box style={positions["authorized-signature"].style}></Box>
        </Draggable>
      </Box>
    </>
  );
});

export default CheckPreview;
