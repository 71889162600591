import { Button, Group, Stack, Textarea, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

export default function AccountForm({ initialValues, action }) {
  const navigate = useNavigate();
  const form = useForm({
    initialValues: initialValues,
    validate: {
      name: isNotEmpty("Enter your name or a business name"),
      routingNumber: isNotEmpty("Enter routing number"),
      accountNumber: isNotEmpty("Enter account munber"),
    },
  });

  return (
    <form method="post" onSubmit={form.onSubmit((v) => action(v))}>
      <Stack gap="xs">
        <TextInput
          withAsterisk
          label="Name On Account"
          name="name"
          {...form.getInputProps("name")}
          autoFocus
        />

        <TextInput
          withAsterisk
          label="Routing Number"
          name="routingNumber"
          {...form.getInputProps("routingNumber")}
        />

        <TextInput
          withAsterisk
          label="Account Number"
          name="accountNumber"
          {...form.getInputProps("accountNumber")}
        />

        <TextInput
          label="Display Name"
          name="displayName"
          placeholder="My Chase Account"
          description="Enter a name to help you identify this account, such as your bank's name."
          {...form.getInputProps("displayName")}
        />

        <Textarea
          label="Your Address"
          description="in 2-3 lines"
          name="address"
          rows={3}
          placeholder={"One Apple Park Way\nCupertino, CA 95014"}
          {...form.getInputProps("address")}
        />

        <TextInput
          label="Starting Check Number"
          name="checkNumber"
          {...form.getInputProps("checkNumber")}
        />

        <Group>
          <Button mt="md" type="submit">
            Submit
          </Button>
          <Button mt="md" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
