import { connect } from "@fireproof/partykit";
import { Database, fireproof } from "use-fireproof";
import { authProvider } from "./auth";

interface DbProvider {
  db: () => Promise<Database>;
}

export const dbProvider: DbProvider = {
  db: async () => {
    let database = fireproof("checks");
    const user = await authProvider.user();
    if (user?.subscription?.status == 'active') {
      connect.partykit(
        { name: user.id, blockstore: database.blockstore },
        import.meta.env.VITE_PARTYKIT_HOST
      );
    } else {
      console.log("initializing db without user");
    }
    return database;
  },
};

export default dbProvider;
