import { RegisterOptions, register } from "@teamhanko/hanko-elements";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authProvider } from "../auth";

const hankoApi = import.meta.env.VITE_HANKO_API_URL;

export default function HankoAuth({ close }) {
  const navigate = useNavigate();
  const location = useLocation();
  const hanko = authProvider.hanko();

  const redirectAfterLogin = useCallback(async () => {
    close();
    await authProvider.user();
    navigate(`${location.pathname}${location.search}`);
  }, [navigate]);

  useEffect(
    () =>
      hanko.onAuthFlowCompleted(() => {
        redirectAfterLogin();
      }),
    [hanko, redirectAfterLogin]
  );

  useEffect(() => {
    const opts: RegisterOptions = {};
    if (import.meta.env.PROD) {
      opts.cookieDomain = ".usequickcheck.com";
    }
    register(hankoApi, opts).catch((error) => {
      console.log(error);
    });
  }, []);

  return <hanko-auth />;
}
