import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { NavLink, useNavigate } from "react-router-dom";
import { dbProvider } from "../../db";

export default function AccountCard({ account }) {
  const navigate = useNavigate();

  async function DeleteAccount({ id }: { id: string }) {
    const db = await dbProvider.db();
    if (window.confirm("Are you sure?")) {
      await db.del(id);
      navigate("/accounts");
    }
  }

  const form = useForm({
    initialValues: {
      id: account._id,
    },
  });

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack className="ph-no-capture" style={{ height: "auto" }}>
        <div>
          <Text size="xs" c="dimmed">
            Name On Account:
          </Text>
          <Text fw={500}>{account.name}</Text>
        </div>
        <div>
          <Text size="xs" c="dimmed">
            Address:
          </Text>
          <Text fw={500} style={{ whiteSpace: "pre-wrap" }}>
            {account.address}
          </Text>
        </div>
        <div>
          <Text size="xs" c="dimmed">
            Routing:
          </Text>
          <Text>{account.routingNumber}</Text>
        </div>
        <div>
          <Text size="xs" c="dimmed">
            Account Number:
          </Text>
          <Text>{account.accountNumber}</Text>
        </div>

        <Group mt="auto" lh="1">
          <Button
            size="compact-xs"
            component={NavLink}
            to={`/accounts/${account._id}/edit`}
          >
            Edit
          </Button>

          <form method="post" onSubmit={form.onSubmit((v) => DeleteAccount(v))}>
            <input type="hidden" name="id" value={account._id} />
            <Button type="submit" variant="danger" size="compact-xs">
              Delete
            </Button>
          </form>
        </Group>
      </Stack>
    </Card>
  );
}
