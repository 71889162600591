import { Button, createTheme } from "@mantine/core";
import classes from './layouts/App.module.css';


export const bgColor = '#fbf9f2';

export const theme = createTheme({
  /* Put your mantine theme override here */
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
    
  },
});
